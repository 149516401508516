<template>
  <div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header text-white">
            Vehicle SAR
          </div>
          <div class="card-body">
            <p class="text-warning">You must provide the full registration number when searching for the vehicle.</p>

            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Vehicle Registration: <span class="tx-danger">*</span></label>
                  <input class="form-control form-control-dark" type="text" v-model="registration" name="registration" placeholder="Enter registration">
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label class="form-control-label" style="width: 100%;">&nbsp;</label>
                  <button :disabled="status !== 'Not Started'" class="btn btn-info" @click="search">Search for Vehicle</button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">

                <p>Status: {{status}}</p>
                <p v-if="downloadUrl">Download Link: <a :href="downloadUrl">Download</a></p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DateTime} from 'luxon';
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
  name: "VehicleSearch",
  data(){
    return {
      registration: "",
      status: "Not Started",
      downloadUrl: null,
      statusUrl: null,
      timeout: null
    }
  },
  mounted(){
    
  },
  methods: {
    async search(){
      axios.get(`https://api.varsanpr.com/api/vehicle/${this.registration}/sar`, {
        headers: authHeader()
      })
      .then(response => {
        this.statusUrl = response.data.statusQueryGetUri;
        this.timeout = setTimeout(this.checkStatus, 3000);
        this.status = "Pending";
      })
      .catch(error => {
        this.$error(error.response.data.message, err);
      });
    },
    async checkStatus(){
      if(this.status === "Completed") return;
      axios.get(this.statusUrl)
      .then(response => {
        this.status = response.data.runtimeStatus;
        if(this.status === "Completed"){
          this.downloadUrl = response.data.output.sas;
        }else{
          if(this.status !== "Failed"){
            this.timeout = setTimeout(this.checkStatus, 3000);
          }
        }
      })
      .catch(error => {
        this.$error(error.response.data.message, err);
        this.timeout = setTimeout(this.checkStatus, 3000);
      });
    }
  }
}
</script>